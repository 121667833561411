/** @format */

import { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  isSameDay,
  isSameYear,
  isBefore,
  isAfter,
  format,
  isSameMonth,
  isSameQuarter,
  formatDistanceToNow,
} from "date-fns";
import debounce from "lodash-es/debounce";
import DatePicker from "@blocks/DatePicker";
import {
  Button,
  ButtonGroup,
  Modal,
  Popover,
  Spinner,
} from "@bphxd/ds-core-react";
import { Date16, Left16, Right16 } from "@bphxd/ds-core-react/lib/icons";
import { uniqueId } from "lodash-es";

export enum IntervalType {
  Live = "Live",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
  Custom = "Custom",
  All = "All",
}

export interface DateRangeType {
  startDate: Date;
  endDate: Date | null;
  intervalType: IntervalType;
}

export interface GenericDateRangeParam {
  intervalType: Exclude<IntervalType, IntervalType.All | IntervalType.Live>;
  startDate: Date;
  endDate: Date;
}

export interface AllDateRangeParam {
  intervalType: IntervalType.All;
  startDate: null;
  endDate: null;
}

export interface LiveDateRangeParam {
  intervalType: IntervalType.Live;
  startDate: Date;
  endDate: null;
}

export type DateRangeParam =
  | GenericDateRangeParam
  | AllDateRangeParam
  | LiveDateRangeParam;

export interface TimeIntervalSwitchProps {
  lastUpdated?: Date;
  type?: IntervalType;
  minDate?: Date;
  maxDate?: Date;
  dateFormatMap?: Record<string, string>;
  timeFormat?: string;
  loading?: boolean;
  /**
   * A filter for date interval buttons. If left undefined, all of the buttons will be visible.
   * If set to null, none of them will be visible.
   */
  intervals?: IntervalType[] | null;
  dateRange?: DateRangeType;
  onChange: (dateRange: DateRangeParam) => void;
  showDatePickerAsModal?: boolean;
}

const dropYear = (dateFormatString: string) =>
  dateFormatString.slice(0, -4).trim();

const getTimeIntervalLabel = ({
  startDate,
  endDate,
  intervalType,
  lastUpdated,
  dateFormatMap,
}: {
  startDate: Date;
  endDate: Date | null;
  intervalType: IntervalType;
  lastUpdated?: Date;
  timeFormat?: string;
  dateFormatMap: Record<string, string>;
}) => {
  if (!startDate) return null;

  switch (intervalType) {
    case IntervalType.Day:
      return isSameDay(new Date(), startDate)
        ? "Today"
        : isSameDay(subDays(new Date(), 1), startDate)
        ? "Yesterday"
        : isSameYear(new Date(), startDate)
        ? format(startDate, dropYear(dateFormatMap.Day))
        : format(startDate, dateFormatMap.Day);
    case IntervalType.Week:
      return !endDate
        ? ""
        : isSameYear(new Date(), startDate)
        ? `${format(startDate, dropYear(dateFormatMap.Week))} - ${format(
            endDate,
            dropYear(dateFormatMap.Week)
          )}`
        : `${format(startDate, dateFormatMap.Week)} - ${format(
            endDate,
            dateFormatMap.Week
          )}`;
    case IntervalType.Month:
      return format(startDate, dateFormatMap.Month);
    case IntervalType.Quarter:
      return format(startDate, dateFormatMap.Quarter);
    case IntervalType.Year:
      return format(startDate, dateFormatMap.Year);
    case IntervalType.Custom:
      return !endDate && isSameYear(new Date(), startDate)
        ? format(startDate, dropYear(dateFormatMap.Custom))
        : !endDate
        ? format(startDate, dateFormatMap.Custom)
        : isSameYear(new Date(), startDate)
        ? `${format(startDate, dropYear(dateFormatMap.Custom))} - ${format(
            endDate,
            dropYear(dateFormatMap.Custom)
          )}`
        : `${format(startDate, dateFormatMap.Custom)} - ${format(
            endDate,
            dateFormatMap.Custom
          )}`;
    case IntervalType.Live:
      return lastUpdated ? (
        <small className="text-secondary">
          Last updated {formatDistanceToNow(lastUpdated)} ago
        </small>
      ) : null;
    case IntervalType.All:
      return "All available data.";
    default:
      return "";
  }
};

const defaultDateRange = {
  startDate: startOfDay(new Date()),
  endDate: null,
  intervalType: IntervalType.Day,
};

const defaultMinDate = subYears(new Date(), 5);

const defaultDateFormatMap = {
  Day: "MMM. do yyyy",
  Week: "EEEEEE, MMM. do yyyy",
  Month: "MMM. yyyy",
  Quarter: "QQQ yyyy",
  Year: "yyyy",
  Custom: "MMM. do yyyy",
  Live: "MMM. do yyyy",
};

const defaultIntervals = [
  IntervalType.Live,
  IntervalType.Day,
  IntervalType.Week,
  IntervalType.Month,
  IntervalType.Quarter,
  IntervalType.Year,
  IntervalType.Custom,
  IntervalType.All,
];

export default memo(function TimeIntervalSwitch(
  props: TimeIntervalSwitchProps
) {
  const {
    lastUpdated,
    loading = false,
    dateRange = defaultDateRange,
    timeFormat = "h:mma",
    dateFormatMap = defaultDateFormatMap,
    onChange,
    minDate = defaultMinDate,
    maxDate = new Date(),
    intervals = defaultIntervals,
    showDatePickerAsModal,
  } = props;

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [intervalType, setIntervalType] = useState(
    defaultDateRange.intervalType
  );
  const [startDate, setStartDate] = useState<Date>(defaultDateRange.startDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultDateRange.endDate);

  useEffect(() => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
    setIntervalType(dateRange.intervalType);
  }, [dateRange]);

  const handleOnChange = useCallback(
    ({ intervalType, startDate, endDate }: DateRangeType) => {
      if (intervalType === IntervalType.Live) {
        onChange({
          intervalType,
          startDate: startOfDay(startDate),
          endDate: null,
        });
        closeDatePicker();
      } else if (intervalType === IntervalType.All) {
        onChange({
          intervalType,
          startDate: null,
          endDate: null,
        });
        closeDatePicker();
      } else if (intervalType === IntervalType.Custom) {
        onChange({
          intervalType,
          startDate: startOfDay(startDate),
          endDate: endOfDay(endDate || startDate),
        });
      } else {
        switch (intervalType) {
          case IntervalType.Day:
            onChange({
              intervalType,
              startDate: startOfDay(startDate),
              endDate: endOfDay(startDate),
            });
            break;
          case IntervalType.Week:
            onChange({
              intervalType,
              startDate: startOfWeek(startDate),
              endDate: endOfWeek(startDate),
            });
            break;
          case IntervalType.Month:
            onChange({
              intervalType,
              startDate: startOfMonth(startDate),
              endDate: endOfMonth(startDate),
            });
            break;
          case IntervalType.Quarter:
            onChange({
              intervalType,
              startDate: startOfQuarter(startDate),
              endDate: endOfQuarter(startDate),
            });
            break;
          case IntervalType.Year:
            onChange({
              intervalType,
              startDate: startOfYear(startDate),
              endDate: endOfYear(startDate),
            });
            break;
          default:
            break;
        }
      }
    },
    [onChange]
  );

  const handleDateChange = useCallback(
    (date) => {
      if (intervalType === IntervalType.Week) {
        setStartDate(startOfWeek(date));
        setEndDate(endOfWeek(date));
      } else if (intervalType === IntervalType.Custom) {
        if (startDate === null) {
          setStartDate(date);
        } else if (endDate === null && isBefore(date, startDate)) {
          setStartDate(date);
        } else if (endDate === null) {
          setEndDate(date);
        } else if (isBefore(date, endDate)) {
          setStartDate(date);
          setEndDate(null);
        } else {
          setEndDate(date);
        }
      } else {
        setStartDate(date);
        setEndDate(date);
      }
    },
    [intervalType, endDate, startDate]
  );

  const handleIntervalTypeChange = useCallback(
    (type: IntervalType) => {
      setIntervalType(type);
      if (type === IntervalType.Week) {
        setStartDate(startOfWeek(startDate));
        setEndDate(endOfWeek(startDate));
      } else if (type !== IntervalType.Live && type !== IntervalType.All) {
        setStartDate(dateRange.startDate);
        setEndDate(null);
      } else {
        handleOnChange({ intervalType: type, startDate, endDate });
      }
    },
    [startDate, endDate, handleOnChange, dateRange.startDate]
  );

  const openDatePicker = () => setDatePickerOpen(true);

  const closeDatePicker = () => setDatePickerOpen(false);

  const updateInterval = useCallback(
    (operation: "add" | "subtract") => {
      let newStartDate = startDate;
      let newEndDate = endDate;
      let dateFn;

      switch (intervalType) {
        case IntervalType.Day:
          dateFn = operation === "add" ? addDays : subDays;
          break;
        case IntervalType.Week:
          dateFn = operation === "add" ? addWeeks : subWeeks;
          break;
        case IntervalType.Month:
          dateFn = operation === "add" ? addMonths : subMonths;
          break;
        case IntervalType.Quarter:
          dateFn = operation === "add" ? addQuarters : subQuarters;
          break;
        case IntervalType.Year:
          dateFn = operation === "add" ? addYears : subYears;
          break;
        default:
          break;
      }

      if (dateFn) {
        newStartDate = dateFn(startDate, 1);
        if (endDate) newEndDate = dateFn(endDate, 1);
      }

      debounce(() => {
        handleOnChange({
          intervalType,
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }, 400)();

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [startDate, endDate, intervalType, handleOnChange]
  );

  const handleIntervalDecrease = useCallback(() => {
    updateInterval("subtract");
  }, [updateInterval]);

  const handleIntervalIncrease = useCallback(() => {
    updateInterval("add");
  }, [updateInterval]);

  const isPrevButtonDisabled = useMemo(() => {
    return (
      isBefore(startDate, minDate) ||
      isSameDay(startDate, minDate) ||
      (intervalType === IntervalType.Month &&
        isSameMonth(startDate, minDate)) ||
      (intervalType === IntervalType.Quarter &&
        isSameQuarter(startDate, minDate)) ||
      (intervalType === IntervalType.Year && isSameYear(startDate, minDate))
    );
  }, [startDate, intervalType, minDate]);

  const isNextButtonDisabled = useMemo(() => {
    const currDate = endDate || startDate;
    return !maxDate
      ? false
      : isAfter(currDate, maxDate) ||
          isSameDay(currDate, maxDate) ||
          (intervalType === IntervalType.Month &&
            isSameMonth(currDate, maxDate)) ||
          (intervalType === IntervalType.Quarter &&
            isSameQuarter(currDate, maxDate)) ||
          (intervalType === IntervalType.Year && isSameYear(currDate, maxDate));
  }, [startDate, endDate, intervalType, maxDate]);

  const handleConfirm = useCallback(() => {
    handleOnChange({ intervalType, startDate, endDate });
    closeDatePicker();
  }, [endDate, startDate, intervalType, handleOnChange]);

  // nastavit to na nejaky default
  const handleCancel = useCallback(() => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
    setIntervalType(dateRange.intervalType);
    closeDatePicker();
  }, [dateRange.startDate, dateRange.endDate, dateRange.intervalType]);

  const datePickerChildren = useMemo(() => {
    return (
      <>
        {intervals ? (
          <ButtonGroup
            size="sm"
            color="shadow"
            rounded="0"
            className="chart-toolbar__interval-type-buttons d-flex flex-wrap"
          >
            {intervals.map((interval) => (
              <Button
                active={intervalType === interval}
                id={interval}
                key={interval}
                onClick={() => handleIntervalTypeChange(interval)}
                className="col-6"
              >
                {interval}
              </Button>
            ))}
          </ButtonGroup>
        ) : null}
        {intervalType !== IntervalType.Live &&
          intervalType !== IntervalType.All && (
            <>
              <div className="x5-px-2 x5-px-md-4 py-4 d-flex justify-content-center">
                <DatePicker
                  inline
                  disabledKeyboardNavigation
                  minDate={minDate}
                  maxDate={maxDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateChange}
                  showHeaderMonthSelect={
                    intervalType !== IntervalType.Month &&
                    intervalType !== IntervalType.Quarter &&
                    intervalType !== IntervalType.Year
                  }
                  showHeaderYearSelect={intervalType !== IntervalType.Year}
                  selectsStart={
                    intervalType === IntervalType.Custom && endDate !== null
                  }
                  selectsEnd={
                    intervalType === IntervalType.Custom && startDate !== null
                  }
                  showMonthYearPicker={intervalType === IntervalType.Month}
                  showQuarterYearPicker={intervalType === IntervalType.Quarter}
                  showYearPicker={intervalType === IntervalType.Year}
                />
              </div>
              <div className="d-flex">
                <Button
                  block
                  rounded="0"
                  level="quartenary"
                  className="m-0"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  block
                  rounded="0"
                  className="m-0"
                  onClick={handleConfirm}
                >
                  Apply
                </Button>
              </div>
            </>
          )}
      </>
    );
  }, [
    endDate,
    handleCancel,
    handleConfirm,
    handleDateChange,
    handleIntervalTypeChange,
    intervalType,
    maxDate,
    minDate,
    startDate,
    intervals,
  ]);

  const datePickerUniqueId = useMemo(() => uniqueId("date-picker-popover"), []);

  return (
    <div className="d-flex flex-wrap-reverse align-items-center justify-content-end">
      <div className="d-flex flex-column align-items-end pe-3">
        {intervalType === IntervalType.Live && (
          <div className="d-flex gap-2">
            <span className="me-1">
              <span className="d-block animate-pulse-circle" />
            </span>
            <div className="fs-6 lh-sm">{intervalType}</div>
          </div>
        )}
        <span className="fs-6 lh-sm text-end">
          {getTimeIntervalLabel({
            endDate,
            startDate,
            intervalType,
            lastUpdated,
            dateFormatMap,
          })}
        </span>
      </div>

      <div className="d-flex align-items-center flex-shrink-0">
        {loading && (
          <div className="pb-2 pe-2 pt-2">
            <Spinner size="sm" />
          </div>
        )}

        {intervalType !== IntervalType.Live &&
          intervalType !== IntervalType.Custom &&
          intervalType !== IntervalType.All && (
            <div className="pb-2 pe-3 pt-2">
              <ButtonGroup size="sm">
                <Button
                  rounded="0"
                  aria-label="Previous date interval"
                  level="tertiary"
                  onClick={handleIntervalDecrease}
                  disabled={loading || isPrevButtonDisabled}
                  Icon={Left16}
                  iconOnly
                />
                <Button
                  rounded="0"
                  aria-label="Next date interval"
                  level="tertiary"
                  onClick={handleIntervalIncrease}
                  disabled={loading || isNextButtonDisabled}
                  iconOnly
                  Icon={Right16}
                />
              </ButtonGroup>
            </div>
          )}
        <div className="pb-2 pt-2">
          <Button
            aria-label="Calendar popup trigger button"
            id={datePickerUniqueId}
            level="tertiary"
            rounded="0"
            size="sm"
            disabled={loading}
            onClick={datePickerOpen ? handleCancel : openDatePicker}
            Icon={Date16}
            iconOnly
          />
        </div>
        {showDatePickerAsModal ? (
          <Modal
            isOpen={datePickerOpen}
            onToggleOpen={handleCancel}
            contentClassName="overflow-hidden"
          >
            <Modal.Header onToggleOpen={handleCancel}>
              Select Date Range
            </Modal.Header>
            <Modal.Body className="p-0">{datePickerChildren}</Modal.Body>
          </Modal>
        ) : (
          <Popover
            isOpen={datePickerOpen}
            flip
            target={datePickerUniqueId}
            popperClassName="p-0 popover-datepicker"
          >
            {datePickerChildren}
          </Popover>
        )}
      </div>
    </div>
  );
});
