import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BodyBuilder: any;
  PathBuilder: any;
};

export type AssetSummary = {
  __typename?: 'AssetSummary';
  assetType: Scalars['String'];
  assetTypeCount?: Maybe<Scalars['Int']>;
  totalInstalledEnergyCapacity: Scalars['Float'];
  totalInstalledPowerCapacity: Scalars['Float'];
};

export type AssetV1 = {
  __typename?: 'AssetV1';
  assetId: Scalars['ID'];
  assetName: Scalars['String'];
  assetType: Scalars['String'];
  installedEnergyCapacity: Scalars['Float'];
  installedPowerCapacity: Scalars['Float'];
  isMainGas: Scalars['Boolean'];
  isMainLoad: Scalars['Boolean'];
  order?: Maybe<Scalars['Float']>;
  participatesInMarkets: Array<MarketData>;
  readings?: Maybe<ReadingsV1>;
};


export type AssetV1ReadingsArgs = {
  end_date: Scalars['String'];
  resolution: Scalars['String'];
  start_date: Scalars['String'];
};

export type AssetV2 = {
  __typename?: 'AssetV2';
  assetId: Scalars['ID'];
  assetName: Scalars['String'];
  assetType: Scalars['String'];
  buildingId: Scalars['ID'];
  installedEnergyCapacity: Scalars['Float'];
  installedPowerCapacity: Scalars['Float'];
  isMainGas: Scalars['Boolean'];
  isMainLoad: Scalars['Boolean'];
  order?: Maybe<Scalars['Float']>;
  parameterTypes: Array<ParameterType>;
  participatesInMarkets: Array<MarketData>;
  siteId: Scalars['ID'];
};


export type Building = {
  __typename?: 'Building';
  assetSummarries?: Maybe<Array<AssetSummary>>;
  buildingId: Scalars['ID'];
  buildingName: Scalars['String'];
  consumption: Consumption;
  geoAddr: Scalars['String'];
  geoCity: Scalars['String'];
  geoCountry: Scalars['String'];
  geoPostalCode: Scalars['String'];
  geoState: Scalars['String'];
  geoStreet: Scalars['String'];
  hasAnalytics: Scalars['Boolean'];
  hasSubmetering: Scalars['Boolean'];
  imgUrl: Scalars['String'];
  isFavorite: Scalars['Boolean'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
  timezone: Scalars['String'];
};

export type Calculation = {
  __typename?: 'Calculation';
  assets?: Maybe<Array<CalculationsAsset>>;
  codeName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CalculationsAsset = {
  __typename?: 'CalculationsAsset';
  assetId: Scalars['ID'];
  assetName: Scalars['String'];
};

export type Consumption = {
  __typename?: 'Consumption';
  currentMonthConsumptionToDate: Scalars['Float'];
};

export type DownloadFilters = {
  __typename?: 'DownloadFilters';
  assetTypes: Array<Scalars['String']>;
  markets: Array<Market>;
  measurementTypes: Array<Scalars['String']>;
};

export type DownloadOption = {
  __typename?: 'DownloadOption';
  assetTypes?: Maybe<Array<Scalars['String']>>;
  buildingId: Scalars['ID'];
  buildingName: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  markets?: Maybe<Array<Market>>;
  measurementTypes?: Maybe<Array<Scalars['String']>>;
  siteId: Scalars['ID'];
  siteName: Scalars['String'];
};

export enum EventCategoryFilterType {
  AllCategories = 'all_categories',
  DispatchReadinessTest = 'dispatch_readiness_test',
  NotificationTest = 'notification_test',
  Regular = 'regular'
}

export enum EventCategoryType {
  DispatchReadinessTest = 'dispatch_readiness_test',
  NotificationTest = 'notification_test',
  Regular = 'regular'
}

export type Market = {
  __typename?: 'Market';
  energyService?: Maybe<Scalars['String']>;
  events?: Maybe<MarketEventReadings>;
  marketId: Scalars['ID'];
  marketName: Scalars['String'];
  marketType: Scalars['String'];
};


export type MarketEventsArgs = {
  end_date: Scalars['String'];
  event_category?: Maybe<EventCategoryFilterType>;
  id: Scalars['ID'];
  start_date: Scalars['String'];
};

export type MarketContact = {
  __typename?: 'MarketContact';
  email: Scalars['String'];
  emailNotifEnable: Scalars['Boolean'];
  user: Scalars['String'];
  userType: Scalars['String'];
};

export type MarketData = {
  __typename?: 'MarketData';
  description?: Maybe<Scalars['String']>;
  energyService: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offTaker: Scalars['String'];
  referencePeriodUsed: Scalars['Float'];
  timezone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MarketEvent = {
  __typename?: 'MarketEvent';
  baseline?: Maybe<Scalars['String']>;
  building: Scalars['ID'];
  buildingName: Scalars['String'];
  category: EventCategoryType;
  endTime?: Maybe<Scalars['String']>;
  expectedEndTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  market: Scalars['ID'];
  marketName: Scalars['String'];
  performancePayment?: Maybe<Scalars['String']>;
  pledge?: Maybe<Scalars['String']>;
  readings: MarketEventReadingsMap;
  response?: Maybe<Scalars['String']>;
  site: Scalars['ID'];
  siteName: Scalars['String'];
  startTime: Scalars['String'];
  status: Scalars['Int'];
  target?: Maybe<Scalars['String']>;
  totalConsumption?: Maybe<Scalars['String']>;
};

export type MarketEventReadings = {
  __typename?: 'MarketEventReadings';
  readings: Array<MarketEvent>;
};

export type MarketEventReadingsMap = {
  __typename?: 'MarketEventReadingsMap';
  baseLine: ReadingV2;
  targetLine: ReadingV2;
};

export type MarketReadings = {
  __typename?: 'MarketReadings';
  aggregate: MarketRevenueAggregate;
  readings: Array<MarketRevenueReading>;
};

export type MarketRevenueAggregate = {
  __typename?: 'MarketRevenueAggregate';
  averageRevenue: Scalars['String'];
  maximumRevenue: Scalars['String'];
  minimumRevenue: Scalars['String'];
  totalRevenue: Scalars['String'];
};

export type MarketRevenueReading = {
  __typename?: 'MarketRevenueReading';
  events: Array<MarketEvent>;
  market: Scalars['ID'];
  marketDefaultUnit?: Maybe<Scalars['String']>;
  marketName: Scalars['String'];
  performanceFactor?: Maybe<Scalars['String']>;
  performanceFactorEstimated: Scalars['Boolean'];
  performancePayment?: Maybe<Scalars['String']>;
  performancePaymentEstimated: Scalars['Boolean'];
  reservationPayment?: Maybe<Scalars['String']>;
  reservationPaymentEstimated: Scalars['Boolean'];
  reservationPaymentTrueUpExplanation: Scalars['Boolean'];
  revenue: Scalars['String'];
  revenueEstimated: Scalars['Boolean'];
  timestamp: Scalars['String'];
};

export type MarketWithEventReadings = {
  __typename?: 'MarketWithEventReadings';
  contacts: Array<MarketContact>;
  energyService: Scalars['String'];
  events: Array<MarketEvent>;
  marketId: Scalars['ID'];
  marketName: Scalars['String'];
  marketType: Scalars['String'];
  zones: Array<MarketZone>;
};

export type MarketZone = {
  __typename?: 'MarketZone';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MeasurementParameter = {
  __typename?: 'MeasurementParameter';
  codeName: Scalars['String'];
  groupCode?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  subgroupCode?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setBuildingFavorite?: Maybe<Building>;
  updateEventStatus?: Maybe<MarketEvent>;
};


export type MutationSetBuildingFavoriteArgs = {
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};


export type MutationUpdateEventStatusArgs = {
  id: Scalars['ID'];
  input: UpdateMarketEventInput;
};

export type ParameterType = {
  __typename?: 'ParameterType';
  codeName: Scalars['String'];
  conversionConstant?: Maybe<Scalars['Int']>;
  measurementParameters: Array<MeasurementParameter>;
  name: Scalars['String'];
  unit: Scalars['String'];
  valueType: Scalars['String'];
};


export type Query = {
  __typename?: 'Query';
  assetSummarries?: Maybe<Array<AssetSummary>>;
  assets?: Maybe<Array<AssetV1>>;
  assetsV2?: Maybe<Array<AssetV2>>;
  buildings?: Maybe<Array<Building>>;
  calculations?: Maybe<Array<Calculation>>;
  downloadFilters?: Maybe<DownloadFilters>;
  downloadOptions?: Maybe<Array<DownloadOption>>;
  marketReadings?: Maybe<MarketReadings>;
  markets?: Maybe<Array<Market>>;
  marketsWithEventReadings?: Maybe<Array<MarketWithEventReadings>>;
  readingsV1?: Maybe<ReadingsV1>;
  readingsV2?: Maybe<ReadingsV2>;
  site?: Maybe<Site>;
  sites?: Maybe<Array<Site>>;
  submeters?: Maybe<Array<Submeter>>;
};


export type QueryAssetSummarriesArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAssetsArgs = {
  end: Scalars['String'];
  id: Scalars['ID'];
  resolution: Scalars['String'];
  start: Scalars['String'];
};


export type QueryAssetsV2Args = {
  asset_category: Scalars['String'];
  id: Scalars['ID'];
};


export type QueryBuildingsArgs = {
  id: Scalars['ID'];
};


export type QueryCalculationsArgs = {
  id: Scalars['ID'];
};


export type QueryDownloadOptionsArgs = {
  asset_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  end_date?: Maybe<Scalars['String']>;
  f_bldng_ids?: Maybe<Array<Scalars['ID']>>;
  f_site_ids?: Maybe<Array<Scalars['ID']>>;
  markets?: Maybe<Array<Maybe<Scalars['String']>>>;
  measurement_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  start_date?: Maybe<Scalars['String']>;
};


export type QueryMarketReadingsArgs = {
  end_date: Scalars['String'];
  event_category?: Maybe<EventCategoryFilterType>;
  id?: Maybe<Scalars['ID']>;
  market_name?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
};


export type QueryMarketsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryMarketsWithEventReadingsArgs = {
  end_date: Scalars['String'];
  id: Scalars['ID'];
  resolution: Scalars['String'];
  start_date: Scalars['String'];
};


export type QueryReadingsV1Args = {
  end_date: Scalars['String'];
  resolution: Scalars['String'];
  start_date: Scalars['String'];
};


export type QueryReadingsV2Args = {
  aggregate_by?: Maybe<Scalars['String']>;
  end_date: Scalars['String'];
  f_asset_ids?: Maybe<Array<Scalars['ID']>>;
  f_asset_types?: Maybe<Array<Scalars['String']>>;
  f_bldng_ids?: Maybe<Array<Scalars['ID']>>;
  f_param_ids?: Maybe<Array<Scalars['ID']>>;
  f_param_types?: Maybe<Array<Scalars['String']>>;
  f_site_ids?: Maybe<Array<Scalars['ID']>>;
  f_virt_asset_ids?: Maybe<Array<Scalars['ID']>>;
  f_virt_asset_types?: Maybe<Array<Scalars['String']>>;
  file_format?: Maybe<Scalars['String']>;
  raw_data_only?: Maybe<Scalars['Boolean']>;
  resolution?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
  tw_diff?: Maybe<Scalars['Boolean']>;
};


export type QuerySiteArgs = {
  siteId: Scalars['ID'];
};


export type QuerySubmetersArgs = {
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resolution?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type ReadingRecord = {
  __typename?: 'ReadingRecord';
  parameterType: Scalars['String'];
  timestamp: Scalars['String'];
  value: Scalars['Float'];
};

export type ReadingV1 = {
  __typename?: 'ReadingV1';
  energy: Scalars['Float'];
  power: Scalars['Float'];
  timestamp: Scalars['String'];
};

export type ReadingV2 = {
  __typename?: 'ReadingV2';
  aggregate: Array<ReadingsAggregateV2>;
  id: Scalars['ID'];
  name: Scalars['String'];
  records: Array<ReadingRecord>;
  timezone?: Maybe<Scalars['String']>;
};

export type ReadingsAggregateV1 = {
  __typename?: 'ReadingsAggregateV1';
  averageEnergy: Scalars['Float'];
  averagePower: Scalars['Float'];
  maximumEnergy: Scalars['Float'];
  maximumPower: Scalars['Float'];
  minimumEnergy: Scalars['Float'];
  minimumPower: Scalars['Float'];
  sumEnergy: Scalars['Float'];
  sumPower: Scalars['Float'];
};

export type ReadingsAggregateV2 = {
  __typename?: 'ReadingsAggregateV2';
  average: Scalars['Float'];
  itemCounts?: Maybe<Scalars['Float']>;
  maximum: Scalars['Float'];
  minimum: Scalars['Float'];
  parameterType: Scalars['String'];
  parameterUnit: Scalars['String'];
  seriesCount?: Maybe<Scalars['String']>;
  sum: Scalars['Float'];
};

export type ReadingsV1 = {
  __typename?: 'ReadingsV1';
  aggregate?: Maybe<ReadingsAggregateV1>;
  readings?: Maybe<Array<ReadingV1>>;
};

export type ReadingsV2 = {
  __typename?: 'ReadingsV2';
  aggregate: Array<ReadingsAggregateV2>;
  readings: Array<ReadingV2>;
};

export type Site = {
  __typename?: 'Site';
  assetSummarries?: Maybe<Array<AssetSummary>>;
  buildings: Array<Building>;
  consumption: Consumption;
  geoAddr: Scalars['String'];
  geoCity: Scalars['String'];
  geoCountry: Scalars['String'];
  geoPostalCode: Scalars['String'];
  geoState: Scalars['String'];
  geoStreet: Scalars['String'];
  imgUrl: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
  siteId: Scalars['ID'];
  siteName: Scalars['String'];
  timezone: Scalars['String'];
};

export type Submeter = {
  __typename?: 'Submeter';
  isCommercialSpace: Scalars['Boolean'];
  readings?: Maybe<SubmeterReadings>;
  submeterCode: Scalars['String'];
  submeterId: Scalars['ID'];
  submeterName: Scalars['String'];
};


export type SubmeterReadingsArgs = {
  end_date: Scalars['String'];
  resolution: Scalars['String'];
  start_date: Scalars['String'];
};

export type SubmeterReading = {
  __typename?: 'SubmeterReading';
  consumption: Scalars['Float'];
  timestamp: Scalars['String'];
};

export type SubmeterReadings = {
  __typename?: 'SubmeterReadings';
  aggregate: SubmeterReadingsAggregate;
  readings: Array<SubmeterReading>;
  units: Scalars['String'];
};

export type SubmeterReadingsAggregate = {
  __typename?: 'SubmeterReadingsAggregate';
  averageConsumption: Scalars['Float'];
  maximumConsumption: Scalars['Float'];
  minimumConsumption: Scalars['Float'];
  sumConsumption: Scalars['Float'];
};

export type UpdateMarketEventInput = {
  status: Scalars['Int'];
};

export type AssetSummaryFragment = { __typename?: 'AssetSummary', assetType: string, assetTypeCount?: Maybe<number>, totalInstalledEnergyCapacity: number, totalInstalledPowerCapacity: number };

export type BasicAssetSummaryFragment = { __typename?: 'AssetSummary', assetType: string, totalInstalledEnergyCapacity: number, totalInstalledPowerCapacity: number };

export type BasicMarketEventFragment = { __typename?: 'MarketEvent', id: string, market: string, marketName: string, site: string, siteName: string, status: number, building: string, buildingName: string, baseline?: Maybe<string>, response?: Maybe<string>, pledge?: Maybe<string>, target?: Maybe<string>, performancePayment?: Maybe<string>, startTime: string, endTime?: Maybe<string>, expectedEndTime?: Maybe<string>, totalConsumption?: Maybe<string>, category: EventCategoryType };

export type BasicSubmeterFragment = { __typename?: 'Submeter', isCommercialSpace: boolean, submeterId: string, submeterName: string, submeterCode: string };

export type MarketDataFragment = { __typename?: 'MarketData', id: string, description?: Maybe<string>, offTaker: string, energyService: string, type: string, name: string, referencePeriodUsed: number, timezone?: Maybe<string> };

export type AssetV1Fragment = { __typename?: 'AssetV1', assetId: string, assetType: string, assetName: string, installedEnergyCapacity: number, installedPowerCapacity: number, isMainLoad: boolean, isMainGas: boolean, order?: Maybe<number>, readings?: Maybe<{ __typename?: 'ReadingsV1', aggregate?: Maybe<(
      { __typename?: 'ReadingsAggregateV1' }
      & ReadingsAggregateV1Fragment
    )>, readings?: Maybe<Array<(
      { __typename?: 'ReadingV1' }
      & ReadingV1Fragment
    )>> }>, participatesInMarkets: Array<(
    { __typename?: 'MarketData' }
    & MarketDataFragment
  )> };

export type AssetV2Fragment = { __typename?: 'AssetV2', assetId: string, assetType: string, assetName: string, buildingId: string, installedEnergyCapacity: number, installedPowerCapacity: number, isMainLoad: boolean, isMainGas: boolean, siteId: string, order?: Maybe<number>, parameterTypes: Array<(
    { __typename?: 'ParameterType' }
    & ParameterTypeFragment
  )>, participatesInMarkets: Array<(
    { __typename?: 'MarketData' }
    & MarketDataFragment
  )> };

export type BasicAssetV1Fragment = { __typename?: 'AssetV1', assetId: string, assetType: string, assetName: string, installedEnergyCapacity: number, installedPowerCapacity: number, isMainLoad: boolean, isMainGas: boolean };

export type BasicBuildingFragment = { __typename?: 'Building', buildingId: string, buildingName: string, geoAddr: string, geoCity: string, geoCountry: string, geoPostalCode: string, geoState: string, geoStreet: string, imgUrl: string, isFavorite: boolean, lat: number, long: number, timezone: string, hasAnalytics: boolean, hasSubmetering: boolean, consumption: (
    { __typename?: 'Consumption' }
    & ConsumptionFragment
  ) };

export type BasicMarketFragment = { __typename?: 'Market', marketId: string, marketName: string, marketType: string, energyService?: Maybe<string> };

export type BasicSiteFragment = { __typename?: 'Site', geoAddr: string, geoCity: string, geoCountry: string, geoPostalCode: string, geoState: string, geoStreet: string, imgUrl: string, lat: number, long: number, siteId: string, siteName: string, timezone: string, buildings: Array<(
    { __typename?: 'Building' }
    & BasicBuildingFragment
  )>, consumption: (
    { __typename?: 'Consumption' }
    & ConsumptionFragment
  ) };

export type CalculationFragment = { __typename?: 'Calculation', name?: Maybe<string>, codeName?: Maybe<string>, assets?: Maybe<Array<(
    { __typename?: 'CalculationsAsset' }
    & CalculationsAssetFragment
  )>> };

export type CalculationsAssetFragment = { __typename?: 'CalculationsAsset', assetId: string, assetName: string };

export type ConsumptionFragment = { __typename?: 'Consumption', currentMonthConsumptionToDate: number };

export type BuildingFragment = { __typename?: 'Building', buildingId: string, buildingName: string, geoAddr: string, geoCity: string, geoCountry: string, geoPostalCode: string, geoState: string, geoStreet: string, imgUrl: string, isFavorite: boolean, lat: number, long: number, timezone: string, hasAnalytics: boolean, hasSubmetering: boolean, consumption: (
    { __typename?: 'Consumption' }
    & ConsumptionFragment
  ), assetSummarries?: Maybe<Array<(
    { __typename?: 'AssetSummary' }
    & BasicAssetSummaryFragment
  )>> };

export type DownloadFiltersFragment = { __typename?: 'DownloadFilters', assetTypes: Array<string>, measurementTypes: Array<string>, markets: Array<(
    { __typename?: 'Market' }
    & DownloadMarketFragment
  )> };

export type DownloadOptionFragment = { __typename?: 'DownloadOption', assetTypes?: Maybe<Array<string>>, buildingId: string, buildingName: string, imgUrl?: Maybe<string>, lat?: Maybe<number>, long?: Maybe<number>, measurementTypes?: Maybe<Array<string>>, siteId: string, siteName: string, markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & DownloadMarketFragment
  )>> };

export type DownloadMarketFragment = { __typename?: 'Market', marketId: string, marketName: string, marketType: string };

export type MarketContactFragment = { __typename?: 'MarketContact', user: string, userType: string, email: string, emailNotifEnable: boolean };

export type MarketEventReadingsFragment = { __typename?: 'MarketEventReadings', readings: Array<(
    { __typename?: 'MarketEvent' }
    & BasicMarketEventFragment
  )> };

export type MarketWithEventReadingsFragment = { __typename?: 'MarketWithEventReadings', marketId: string, marketName: string, marketType: string, energyService: string, zones: Array<(
    { __typename?: 'MarketZone' }
    & MarketZoneFragment
  )>, contacts: Array<(
    { __typename?: 'MarketContact' }
    & MarketContactFragment
  )>, events: Array<(
    { __typename?: 'MarketEvent' }
    & MarketEventFragment
  )> };

export type MarketEventFragment = { __typename?: 'MarketEvent', id: string, market: string, marketName: string, site: string, siteName: string, status: number, building: string, buildingName: string, baseline?: Maybe<string>, response?: Maybe<string>, pledge?: Maybe<string>, target?: Maybe<string>, performancePayment?: Maybe<string>, startTime: string, endTime?: Maybe<string>, expectedEndTime?: Maybe<string>, totalConsumption?: Maybe<string>, category: EventCategoryType, readings: { __typename?: 'MarketEventReadingsMap', baseLine: (
      { __typename?: 'ReadingV2' }
      & ReadingV2Fragment
    ), targetLine: (
      { __typename?: 'ReadingV2' }
      & ReadingV2Fragment
    ) } };

export type MarketRevenueReadingFragment = { __typename?: 'MarketRevenueReading', market: string, marketName: string, revenue: string, revenueEstimated: boolean, reservationPayment?: Maybe<string>, reservationPaymentEstimated: boolean, reservationPaymentTrueUpExplanation: boolean, performancePayment?: Maybe<string>, performancePaymentEstimated: boolean, performanceFactor?: Maybe<string>, performanceFactorEstimated: boolean, timestamp: string, marketDefaultUnit?: Maybe<string>, events: Array<(
    { __typename?: 'MarketEvent' }
    & BasicMarketEventFragment
  )> };

export type MarketReadingsFragment = { __typename?: 'MarketReadings', aggregate: (
    { __typename?: 'MarketRevenueAggregate' }
    & MarketRevenueAggregateFragment
  ), readings: Array<(
    { __typename?: 'MarketRevenueReading' }
    & MarketRevenueReadingFragment
  )> };

export type MarketFragment = { __typename?: 'Market', marketId: string, marketName: string, marketType: string, energyService?: Maybe<string>, events?: Maybe<(
    { __typename?: 'MarketEventReadings' }
    & MarketEventReadingsFragment
  )> };

export type MarketRevenueAggregateFragment = { __typename?: 'MarketRevenueAggregate', maximumRevenue: string, minimumRevenue: string, averageRevenue: string, totalRevenue: string };

export type MarketZoneFragment = { __typename?: 'MarketZone', code: string, name: string };

export type MeasurementParameterFragment = { __typename?: 'MeasurementParameter', id: string, name: string, codeName: string, groupCode?: Maybe<number>, subgroupCode?: Maybe<number> };

export type ParameterTypeFragment = { __typename?: 'ParameterType', name: string, codeName: string, unit: string, valueType: string, conversionConstant?: Maybe<number>, measurementParameters: Array<(
    { __typename?: 'MeasurementParameter' }
    & MeasurementParameterFragment
  )> };

export type ReadingsAggregateV1Fragment = { __typename?: 'ReadingsAggregateV1', averageEnergy: number, averagePower: number, maximumEnergy: number, maximumPower: number, minimumEnergy: number, minimumPower: number, sumEnergy: number, sumPower: number };

export type ReadingsAggregateV2Fragment = { __typename?: 'ReadingsAggregateV2', average: number, itemCounts?: Maybe<number>, maximum: number, minimum: number, sum: number, parameterType: string, parameterUnit: string, seriesCount?: Maybe<string> };

export type ReadingV1Fragment = { __typename?: 'ReadingV1', energy: number, power: number, timestamp: string };

export type ReadingsV1Fragment = { __typename?: 'ReadingsV1', aggregate?: Maybe<(
    { __typename?: 'ReadingsAggregateV1' }
    & ReadingsAggregateV1Fragment
  )>, readings?: Maybe<Array<(
    { __typename?: 'ReadingV1' }
    & ReadingV1Fragment
  )>> };

export type ReadingV2Fragment = { __typename?: 'ReadingV2', id: string, name: string, timezone?: Maybe<string>, aggregate: Array<(
    { __typename?: 'ReadingsAggregateV2' }
    & ReadingsAggregateV2Fragment
  )>, records: Array<(
    { __typename?: 'ReadingRecord' }
    & ReadingRecordFragment
  )> };

export type ReadingsV2Fragment = { __typename?: 'ReadingsV2', aggregate: Array<(
    { __typename?: 'ReadingsAggregateV2' }
    & ReadingsAggregateV2Fragment
  )>, readings: Array<(
    { __typename?: 'ReadingV2' }
    & ReadingV2Fragment
  )> };

export type ReadingRecordFragment = { __typename?: 'ReadingRecord', value: number, timestamp: string, parameterType: string };

export type SiteFragment = { __typename?: 'Site', geoAddr: string, geoCity: string, geoCountry: string, geoPostalCode: string, geoState: string, geoStreet: string, imgUrl: string, lat: number, long: number, siteId: string, siteName: string, timezone: string, buildings: Array<(
    { __typename?: 'Building' }
    & BasicBuildingFragment
  )>, consumption: (
    { __typename?: 'Consumption' }
    & ConsumptionFragment
  ), assetSummarries?: Maybe<Array<(
    { __typename?: 'AssetSummary' }
    & BasicAssetSummaryFragment
  )>> };

export type SubmeterFragment = { __typename?: 'Submeter', submeterId: string, submeterName: string, submeterCode: string, isCommercialSpace: boolean, readings?: Maybe<(
    { __typename?: 'SubmeterReadings' }
    & SubmeterReadingsFragment
  )> };

export type SubmeterReadingFragment = { __typename?: 'SubmeterReading', consumption: number, timestamp: string };

export type SubmeterReadingsFragment = { __typename?: 'SubmeterReadings', units: string, aggregate: (
    { __typename?: 'SubmeterReadingsAggregate' }
    & SubmeterReadingsAggregateFragment
  ), readings: Array<(
    { __typename?: 'SubmeterReading' }
    & SubmeterReadingFragment
  )> };

export type SubmeterReadingsAggregateFragment = { __typename?: 'SubmeterReadingsAggregate', averageConsumption: number, maximumConsumption: number, minimumConsumption: number, sumConsumption: number };

export type UpdateEventStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateMarketEventInput;
}>;


export type UpdateEventStatusMutation = { __typename?: 'Mutation', updateEventStatus?: Maybe<(
    { __typename?: 'MarketEvent' }
    & MarketEventFragment
  )> };

export type SetBuildingFavoriteMutationVariables = Exact<{
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  bodyBuilder?: Maybe<Scalars['BodyBuilder']>;
}>;


export type SetBuildingFavoriteMutation = { __typename?: 'Mutation', setBuildingFavorite?: Maybe<(
    { __typename?: 'Building' }
    & BasicBuildingFragment
  )> };

export type AssetsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  resolution: Scalars['String'];
}>;


export type AssetsQuery = { __typename?: 'Query', assets?: Maybe<Array<(
    { __typename?: 'AssetV1' }
    & AssetV1Fragment
  )>>, assetSummarries?: Maybe<Array<(
    { __typename?: 'AssetSummary' }
    & BasicAssetSummaryFragment
  )>> };

export type AssetsV2QueryVariables = Exact<{
  assetCategory: Scalars['String'];
  buildingId: Scalars['ID'];
}>;


export type AssetsV2Query = { __typename?: 'Query', assetsV2?: Maybe<Array<(
    { __typename?: 'AssetV2' }
    & AssetV2Fragment
  )>> };

export type AssetSummarriesQueryVariables = Exact<{
  buildingId: Scalars['ID'];
}>;


export type AssetSummarriesQuery = { __typename?: 'Query', assetSummarries?: Maybe<Array<(
    { __typename?: 'AssetSummary' }
    & BasicAssetSummaryFragment
  )>> };

export type BasicSiteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BasicSiteQuery = { __typename?: 'Query', site?: Maybe<(
    { __typename?: 'Site' }
    & BasicSiteFragment
  )> };

export type BuildingsQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type BuildingsQuery = { __typename?: 'Query', buildings?: Maybe<Array<(
    { __typename?: 'Building' }
    & BuildingFragment
  )>> };

export type BuildingCalculationsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
}>;


export type BuildingCalculationsQuery = { __typename?: 'Query', calculations?: Maybe<Array<(
    { __typename?: 'Calculation' }
    & CalculationFragment
  )>> };

export type ReadingsQueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
  resolution: Scalars['String'];
}>;


export type ReadingsQuery = { __typename?: 'Query', readingsV1?: Maybe<(
    { __typename?: 'ReadingsV1' }
    & ReadingsV1Fragment
  )> };

export type ReadingsV2QueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
  resolution?: Maybe<Scalars['String']>;
  twDiff?: Maybe<Scalars['Boolean']>;
  aggregateBy?: Maybe<Scalars['String']>;
  siteIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  buildingIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  assetIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  virtualAssetIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  paramIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  assetTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  virtualAssetTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  paramTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  rawDataOnly?: Maybe<Scalars['Boolean']>;
  fileFormat?: Maybe<Scalars['String']>;
  pathBuilder?: Maybe<Scalars['PathBuilder']>;
}>;


export type ReadingsV2Query = { __typename?: 'Query', readingsV2?: Maybe<(
    { __typename?: 'ReadingsV2' }
    & ReadingsV2Fragment
  )> };

export type SiteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SiteQuery = { __typename?: 'Query', site?: Maybe<(
    { __typename?: 'Site' }
    & SiteFragment
  )> };

export type SitesQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesQuery = { __typename?: 'Query', sites?: Maybe<Array<(
    { __typename?: 'Site' }
    & SiteFragment
  )>>, assetSummarries?: Maybe<Array<(
    { __typename?: 'AssetSummary' }
    & AssetSummaryFragment
  )>> };

export type BuildingMarketEventsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type BuildingMarketEventsQuery = { __typename?: 'Query', markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & MarketFragment
  )>> };

export type BuildingMarketTotalReadingsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type BuildingMarketTotalReadingsQuery = { __typename?: 'Query', marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type BuildingMarketsWithReadingsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type BuildingMarketsWithReadingsQuery = { __typename?: 'Query', markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & BasicMarketFragment
  )>>, marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type BuildingMarketsWithEventReadingsQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  resolution: Scalars['String'];
}>;


export type BuildingMarketsWithEventReadingsQuery = { __typename?: 'Query', marketsWithEventReadings?: Maybe<Array<(
    { __typename?: 'MarketWithEventReadings' }
    & MarketWithEventReadingsFragment
  )>> };

export type SiteMarketReadingsQueryVariables = Exact<{
  siteId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  marketName?: Maybe<Scalars['String']>;
  eventCategory?: EventCategoryFilterType;
}>;


export type SiteMarketReadingsQuery = { __typename?: 'Query', marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type SiteMarketsWithReadingsQueryVariables = Exact<{
  siteId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  marketName?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type SiteMarketsWithReadingsQuery = { __typename?: 'Query', markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & BasicMarketFragment
  )>>, marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type SiteMarketTotalReadingsQueryVariables = Exact<{
  siteId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type SiteMarketTotalReadingsQuery = { __typename?: 'Query', marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type SiteMarketsQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SiteMarketsQuery = { __typename?: 'Query', markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & BasicMarketFragment
  )>> };

export type SitesMarketTotalReadingsQueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type SitesMarketTotalReadingsQuery = { __typename?: 'Query', marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type SitesMarketsWithReadingsQueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
  marketName?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<EventCategoryFilterType>;
}>;


export type SitesMarketsWithReadingsQuery = { __typename?: 'Query', markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & BasicMarketFragment
  )>>, marketReadings?: Maybe<(
    { __typename?: 'MarketReadings' }
    & MarketReadingsFragment
  )> };

export type BasicSubmetersQueryVariables = Exact<{
  buildingId: Scalars['ID'];
}>;


export type BasicSubmetersQuery = { __typename?: 'Query', submeters?: Maybe<Array<(
    { __typename?: 'Submeter' }
    & BasicSubmeterFragment
  )>> };

export type SubmetersQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
  resolution: Scalars['String'];
}>;


export type SubmetersQuery = { __typename?: 'Query', submeters?: Maybe<Array<(
    { __typename?: 'Submeter' }
    & SubmeterFragment
  )>> };

export type DownloadFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadFiltersQuery = { __typename?: 'Query', downloadFilters?: Maybe<(
    { __typename?: 'DownloadFilters' }
    & DownloadFiltersFragment
  )> };

export type DownloadOptionsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  siteIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  buildingIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  marketNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  assetTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  measurementTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  pathBuilder?: Maybe<Scalars['PathBuilder']>;
}>;


export type DownloadOptionsQuery = { __typename?: 'Query', downloadOptions?: Maybe<Array<(
    { __typename?: 'DownloadOption' }
    & DownloadOptionFragment
  )>> };

export const AssetSummaryFragmentDoc = gql`
    fragment AssetSummary on AssetSummary {
  assetType
  assetTypeCount
  totalInstalledEnergyCapacity
  totalInstalledPowerCapacity
}
    `;
export const BasicSubmeterFragmentDoc = gql`
    fragment BasicSubmeter on Submeter {
  isCommercialSpace
  submeterId
  submeterName
  submeterCode
}
    `;
export const ReadingsAggregateV1FragmentDoc = gql`
    fragment ReadingsAggregateV1 on ReadingsAggregateV1 {
  averageEnergy
  averagePower
  maximumEnergy
  maximumPower
  minimumEnergy
  minimumPower
  sumEnergy
  sumPower
}
    `;
export const ReadingV1FragmentDoc = gql`
    fragment ReadingV1 on ReadingV1 {
  energy
  power
  timestamp
}
    `;
export const MarketDataFragmentDoc = gql`
    fragment MarketData on MarketData {
  id
  description
  offTaker
  energyService
  type
  name
  referencePeriodUsed
  timezone
}
    `;
export const AssetV1FragmentDoc = gql`
    fragment AssetV1 on AssetV1 {
  assetId @export(as: "assetId")
  assetType
  assetName
  installedEnergyCapacity
  installedPowerCapacity
  isMainLoad
  isMainGas
  readings(start_date: $start, end_date: $end, resolution: $resolution) @rest(type: "AssetReadings", path: "/readings/assets/{exportVariables.assetId}?start_date={args.start_date}&end_date={args.end_date}&resolution={args.resolution}", method: "GET") {
    aggregate @type(name: "ReadingsAggregateV1") {
      ...ReadingsAggregateV1
    }
    readings @type(name: "ReadingV1") {
      ...ReadingV1
    }
  }
  participatesInMarkets @type(name: "MarketData") {
    ...MarketData
  }
  order
}
    ${ReadingsAggregateV1FragmentDoc}
${ReadingV1FragmentDoc}
${MarketDataFragmentDoc}`;
export const MeasurementParameterFragmentDoc = gql`
    fragment MeasurementParameter on MeasurementParameter {
  id
  name
  codeName
  groupCode
  subgroupCode
}
    `;
export const ParameterTypeFragmentDoc = gql`
    fragment ParameterType on ParameterType {
  name
  codeName
  unit
  valueType
  conversionConstant
  measurementParameters @type(name: "MeasurementParameter") {
    ...MeasurementParameter
  }
}
    ${MeasurementParameterFragmentDoc}`;
export const AssetV2FragmentDoc = gql`
    fragment AssetV2 on AssetV2 {
  assetId
  assetType
  assetName
  buildingId
  installedEnergyCapacity
  installedPowerCapacity
  isMainLoad
  isMainGas
  parameterTypes @type(name: "ParameterType") {
    ...ParameterType
  }
  siteId
  participatesInMarkets @type(name: "MarketData") {
    ...MarketData
  }
  order
}
    ${ParameterTypeFragmentDoc}
${MarketDataFragmentDoc}`;
export const BasicAssetV1FragmentDoc = gql`
    fragment BasicAssetV1 on AssetV1 {
  assetId
  assetType
  assetName
  installedEnergyCapacity
  installedPowerCapacity
  isMainLoad
  isMainGas
}
    `;
export const BasicMarketFragmentDoc = gql`
    fragment BasicMarket on Market {
  marketId
  marketName
  marketType
  energyService
}
    `;
export const ConsumptionFragmentDoc = gql`
    fragment Consumption on Consumption {
  currentMonthConsumptionToDate
}
    `;
export const BasicBuildingFragmentDoc = gql`
    fragment BasicBuilding on Building {
  buildingId
  buildingName
  consumption @type(name: "Consumption") {
    ...Consumption
  }
  geoAddr
  geoCity
  geoCountry
  geoPostalCode
  geoState
  geoStreet
  imgUrl
  isFavorite
  lat
  long
  timezone
  hasAnalytics
  hasSubmetering
}
    ${ConsumptionFragmentDoc}`;
export const BasicSiteFragmentDoc = gql`
    fragment BasicSite on Site {
  buildings @type(name: "Building") {
    ...BasicBuilding
  }
  consumption @type(name: "Consumption") {
    ...Consumption
  }
  geoAddr
  geoCity
  geoCountry
  geoPostalCode
  geoState
  geoStreet
  imgUrl
  lat
  long
  siteId
  siteName
  timezone
}
    ${BasicBuildingFragmentDoc}
${ConsumptionFragmentDoc}`;
export const CalculationsAssetFragmentDoc = gql`
    fragment CalculationsAsset on CalculationsAsset {
  assetId
  assetName
}
    `;
export const CalculationFragmentDoc = gql`
    fragment Calculation on Calculation {
  name
  codeName
  assets @type(name: "CalculationsAsset") {
    ...CalculationsAsset
  }
}
    ${CalculationsAssetFragmentDoc}`;
export const BasicAssetSummaryFragmentDoc = gql`
    fragment BasicAssetSummary on AssetSummary {
  assetType
  totalInstalledEnergyCapacity
  totalInstalledPowerCapacity
}
    `;
export const BuildingFragmentDoc = gql`
    fragment Building on Building {
  buildingId @export(as: "buildingId")
  buildingName
  geoAddr
  geoCity
  geoCountry
  geoPostalCode
  geoState
  geoStreet
  imgUrl
  isFavorite
  lat
  long
  timezone
  consumption @type(name: "Consumption") {
    ...Consumption
  }
  hasAnalytics
  hasSubmetering
  assetSummarries @rest(type: "[AssetSummary]", path: "/buildings/{exportVariables.buildingId}/asset_summary", method: "GET") {
    ...BasicAssetSummary
  }
}
    ${ConsumptionFragmentDoc}
${BasicAssetSummaryFragmentDoc}`;
export const DownloadMarketFragmentDoc = gql`
    fragment DownloadMarket on Market {
  marketId
  marketName
  marketType
}
    `;
export const DownloadFiltersFragmentDoc = gql`
    fragment DownloadFilters on DownloadFilters {
  assetTypes
  measurementTypes
  markets @type(name: "DownloadMarket") {
    ...DownloadMarket
  }
}
    ${DownloadMarketFragmentDoc}`;
export const DownloadOptionFragmentDoc = gql`
    fragment DownloadOption on DownloadOption {
  assetTypes
  buildingId
  buildingName
  imgUrl
  lat
  long
  markets @type(name: "DownloadMarket") {
    ...DownloadMarket
  }
  measurementTypes
  siteId
  siteName
}
    ${DownloadMarketFragmentDoc}`;
export const MarketZoneFragmentDoc = gql`
    fragment MarketZone on MarketZone {
  code
  name
}
    `;
export const MarketContactFragmentDoc = gql`
    fragment MarketContact on MarketContact {
  user
  userType
  email
  emailNotifEnable
}
    `;
export const ReadingsAggregateV2FragmentDoc = gql`
    fragment ReadingsAggregateV2 on ReadingsAggregateV2 {
  average
  itemCounts
  maximum
  minimum
  sum
  parameterType
  parameterUnit
  seriesCount
}
    `;
export const ReadingRecordFragmentDoc = gql`
    fragment ReadingRecord on ReadingRecord {
  value
  timestamp
  parameterType
}
    `;
export const ReadingV2FragmentDoc = gql`
    fragment ReadingV2 on ReadingV2 {
  aggregate @type(name: "ReadingsAggregateV2") {
    ...ReadingsAggregateV2
  }
  id
  name
  records @type(name: "ReadingRecord") {
    ...ReadingRecord
  }
  timezone
}
    ${ReadingsAggregateV2FragmentDoc}
${ReadingRecordFragmentDoc}`;
export const MarketEventFragmentDoc = gql`
    fragment MarketEvent on MarketEvent {
  id
  market
  marketName
  site
  siteName
  status
  building
  buildingName
  baseline
  response
  pledge
  target
  performancePayment
  startTime
  endTime
  expectedEndTime
  totalConsumption
  category
  readings @type(name: "MarketEventReadingsMap") {
    baseLine @type(name: "ReadingV2") {
      ...ReadingV2
    }
    targetLine @type(name: "ReadingV2") {
      ...ReadingV2
    }
  }
}
    ${ReadingV2FragmentDoc}`;
export const MarketWithEventReadingsFragmentDoc = gql`
    fragment MarketWithEventReadings on MarketWithEventReadings {
  marketId
  marketName
  marketType
  energyService
  zones @type(name: "MarketZone") {
    ...MarketZone
  }
  contacts @type(name: "MarketContact") {
    ...MarketContact
  }
  events @type(name: "MarketEvent") {
    ...MarketEvent
  }
}
    ${MarketZoneFragmentDoc}
${MarketContactFragmentDoc}
${MarketEventFragmentDoc}`;
export const MarketRevenueAggregateFragmentDoc = gql`
    fragment MarketRevenueAggregate on MarketRevenueAggregate {
  maximumRevenue
  minimumRevenue
  averageRevenue
  totalRevenue
}
    `;
export const BasicMarketEventFragmentDoc = gql`
    fragment BasicMarketEvent on MarketEvent {
  id
  market
  marketName
  site
  siteName
  status
  building
  buildingName
  baseline
  response
  pledge
  target
  performancePayment
  startTime
  endTime
  expectedEndTime
  totalConsumption
  category
}
    `;
export const MarketRevenueReadingFragmentDoc = gql`
    fragment MarketRevenueReading on MarketRevenueReading {
  events @type(name: "MarketEvent") {
    ...BasicMarketEvent
  }
  market
  marketName
  revenue
  revenueEstimated
  reservationPayment
  reservationPaymentEstimated
  reservationPaymentTrueUpExplanation
  performancePayment
  performancePaymentEstimated
  performanceFactor
  performanceFactorEstimated
  timestamp
  marketDefaultUnit
}
    ${BasicMarketEventFragmentDoc}`;
export const MarketReadingsFragmentDoc = gql`
    fragment MarketReadings on MarketReadings {
  aggregate @type(name: "MarketRevenueAggregate") {
    ...MarketRevenueAggregate
  }
  readings @type(name: "MarketRevenueReading") {
    ...MarketRevenueReading
  }
}
    ${MarketRevenueAggregateFragmentDoc}
${MarketRevenueReadingFragmentDoc}`;
export const MarketEventReadingsFragmentDoc = gql`
    fragment MarketEventReadings on MarketEventReadings {
  readings @type(name: "MarketEvent") {
    ...BasicMarketEvent
  }
}
    ${BasicMarketEventFragmentDoc}`;
export const MarketFragmentDoc = gql`
    fragment Market on Market {
  marketId
  marketName @export(as: "marketName")
  marketType
  energyService
  events(
    id: $buildingId
    start_date: $start
    end_date: $end
    event_category: $eventCategory
  ) @rest(type: "MarketEventReadings", path: "/market/events/buildings/{args.id}?start_date={args.start_date}&end_date={args.end_date}&market_name={exportVariables.marketName}&event_category={args.event_category}", method: "GET") {
    ...MarketEventReadings
  }
}
    ${MarketEventReadingsFragmentDoc}`;
export const ReadingsV1FragmentDoc = gql`
    fragment ReadingsV1 on ReadingsV1 {
  aggregate @type(name: "ReadingsAggregateV1") {
    ...ReadingsAggregateV1
  }
  readings @type(name: "ReadingV1") {
    ...ReadingV1
  }
}
    ${ReadingsAggregateV1FragmentDoc}
${ReadingV1FragmentDoc}`;
export const ReadingsV2FragmentDoc = gql`
    fragment ReadingsV2 on ReadingsV2 {
  aggregate @type(name: "ReadingsAggregateV2") {
    ...ReadingsAggregateV2
  }
  readings @type(name: "ReadingV2") {
    ...ReadingV2
  }
}
    ${ReadingsAggregateV2FragmentDoc}
${ReadingV2FragmentDoc}`;
export const SiteFragmentDoc = gql`
    fragment Site on Site {
  buildings @type(name: "Building") {
    ...BasicBuilding
  }
  consumption @type(name: "Consumption") {
    ...Consumption
  }
  geoAddr
  geoCity
  geoCountry
  geoPostalCode
  geoState
  geoStreet
  imgUrl
  lat
  long
  siteId @export(as: "siteId")
  siteName
  timezone
  assetSummarries @rest(type: "[AssetSummary]", path: "/sites/{exportVariables.siteId}/asset_summary", method: "GET") {
    ...BasicAssetSummary
  }
}
    ${BasicBuildingFragmentDoc}
${ConsumptionFragmentDoc}
${BasicAssetSummaryFragmentDoc}`;
export const SubmeterReadingsAggregateFragmentDoc = gql`
    fragment SubmeterReadingsAggregate on SubmeterReadingsAggregate {
  averageConsumption
  maximumConsumption
  minimumConsumption
  sumConsumption
}
    `;
export const SubmeterReadingFragmentDoc = gql`
    fragment SubmeterReading on SubmeterReading {
  consumption
  timestamp
}
    `;
export const SubmeterReadingsFragmentDoc = gql`
    fragment SubmeterReadings on SubmeterReadings {
  aggregate @type(name: "SubmeterReadingsAggregate") {
    ...SubmeterReadingsAggregate
  }
  units
  readings @type(name: "SubmeterReading") {
    ...SubmeterReading
  }
}
    ${SubmeterReadingsAggregateFragmentDoc}
${SubmeterReadingFragmentDoc}`;
export const SubmeterFragmentDoc = gql`
    fragment Submeter on Submeter {
  submeterId @export(as: "submeterId")
  readings(start_date: $start, end_date: $end, resolution: $resolution) @rest(type: "SubmeterReadings", path: "/readings/submeters/{exportVariables.submeterId}?start_date={args.start_date}&end_date={args.end_date}&resolution={args.resolution}", method: "GET") {
    ...SubmeterReadings
  }
  submeterName
  submeterCode
  isCommercialSpace
}
    ${SubmeterReadingsFragmentDoc}`;
export const UpdateEventStatusDocument = gql`
    mutation updateEventStatus($id: ID!, $input: UpdateMarketEventInput!) {
  updateEventStatus(id: $id, input: $input) @rest(type: "MarketEvent", path: "/market/events/{args.id}", method: "PATCH") {
    ...MarketEvent
  }
}
    ${MarketEventFragmentDoc}`;
export type UpdateEventStatusMutationFn = Apollo.MutationFunction<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>;

/**
 * __useUpdateEventStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventStatusMutation, { data, loading, error }] = useUpdateEventStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>(UpdateEventStatusDocument, options);
      }
export type UpdateEventStatusMutationHookResult = ReturnType<typeof useUpdateEventStatusMutation>;
export type UpdateEventStatusMutationResult = Apollo.MutationResult<UpdateEventStatusMutation>;
export type UpdateEventStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>;
export const SetBuildingFavoriteDocument = gql`
    mutation setBuildingFavorite($id: ID!, $isFavorite: Boolean!, $bodyBuilder: BodyBuilder) {
  setBuildingFavorite(id: $id, isFavorite: $isFavorite) @rest(type: "Building", path: "/buildings/{args.id}/favorite", method: "PATCH", bodyBuilder: $bodyBuilder) {
    ...BasicBuilding
  }
}
    ${BasicBuildingFragmentDoc}`;
export type SetBuildingFavoriteMutationFn = Apollo.MutationFunction<SetBuildingFavoriteMutation, SetBuildingFavoriteMutationVariables>;

/**
 * __useSetBuildingFavoriteMutation__
 *
 * To run a mutation, you first call `useSetBuildingFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBuildingFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBuildingFavoriteMutation, { data, loading, error }] = useSetBuildingFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFavorite: // value for 'isFavorite'
 *      bodyBuilder: // value for 'bodyBuilder'
 *   },
 * });
 */
export function useSetBuildingFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<SetBuildingFavoriteMutation, SetBuildingFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBuildingFavoriteMutation, SetBuildingFavoriteMutationVariables>(SetBuildingFavoriteDocument, options);
      }
export type SetBuildingFavoriteMutationHookResult = ReturnType<typeof useSetBuildingFavoriteMutation>;
export type SetBuildingFavoriteMutationResult = Apollo.MutationResult<SetBuildingFavoriteMutation>;
export type SetBuildingFavoriteMutationOptions = Apollo.BaseMutationOptions<SetBuildingFavoriteMutation, SetBuildingFavoriteMutationVariables>;
export const AssetsDocument = gql`
    query Assets($buildingId: ID!, $start: String!, $end: String!, $resolution: String!) {
  assets(id: $buildingId, start: $start, end: $end, resolution: $resolution) @rest(type: "[AssetV1]", path: "/buildings/{args.id}/assets", method: "GET") {
    ...AssetV1
  }
  assetSummarries(id: $buildingId) @rest(type: "[AssetSummary]", path: "/buildings/{args.id}/asset_summary", method: "GET") {
    ...BasicAssetSummary
  }
}
    ${AssetV1FragmentDoc}
${BasicAssetSummaryFragmentDoc}`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
      }
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
        }
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const AssetsV2Document = gql`
    query AssetsV2($assetCategory: String!, $buildingId: ID!) {
  assetsV2(asset_category: $assetCategory, id: $buildingId) @rest(type: "[AssetV2]", path: "/buildings/{args.id}/assets?asset_category={args.asset_category}", method: "GET", endpoint: "v2") {
    ...AssetV2
  }
}
    ${AssetV2FragmentDoc}`;

/**
 * __useAssetsV2Query__
 *
 * To run a query within a React component, call `useAssetsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAssetsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsV2Query({
 *   variables: {
 *      assetCategory: // value for 'assetCategory'
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useAssetsV2Query(baseOptions: Apollo.QueryHookOptions<AssetsV2Query, AssetsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsV2Query, AssetsV2QueryVariables>(AssetsV2Document, options);
      }
export function useAssetsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsV2Query, AssetsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsV2Query, AssetsV2QueryVariables>(AssetsV2Document, options);
        }
export type AssetsV2QueryHookResult = ReturnType<typeof useAssetsV2Query>;
export type AssetsV2LazyQueryHookResult = ReturnType<typeof useAssetsV2LazyQuery>;
export type AssetsV2QueryResult = Apollo.QueryResult<AssetsV2Query, AssetsV2QueryVariables>;
export const AssetSummarriesDocument = gql`
    query AssetSummarries($buildingId: ID!) {
  assetSummarries(id: $buildingId) @rest(type: "[AssetSummary]", path: "/buildings/{args.id}/asset_summary", method: "GET") {
    ...BasicAssetSummary
  }
}
    ${BasicAssetSummaryFragmentDoc}`;

/**
 * __useAssetSummarriesQuery__
 *
 * To run a query within a React component, call `useAssetSummarriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetSummarriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetSummarriesQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useAssetSummarriesQuery(baseOptions: Apollo.QueryHookOptions<AssetSummarriesQuery, AssetSummarriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetSummarriesQuery, AssetSummarriesQueryVariables>(AssetSummarriesDocument, options);
      }
export function useAssetSummarriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetSummarriesQuery, AssetSummarriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetSummarriesQuery, AssetSummarriesQueryVariables>(AssetSummarriesDocument, options);
        }
export type AssetSummarriesQueryHookResult = ReturnType<typeof useAssetSummarriesQuery>;
export type AssetSummarriesLazyQueryHookResult = ReturnType<typeof useAssetSummarriesLazyQuery>;
export type AssetSummarriesQueryResult = Apollo.QueryResult<AssetSummarriesQuery, AssetSummarriesQueryVariables>;
export const BasicSiteDocument = gql`
    query BasicSite($id: ID!) {
  site(siteId: $id) @rest(type: "Site", path: "/sites/{args.siteId}", method: "GET") {
    ...BasicSite
  }
}
    ${BasicSiteFragmentDoc}`;

/**
 * __useBasicSiteQuery__
 *
 * To run a query within a React component, call `useBasicSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasicSiteQuery(baseOptions: Apollo.QueryHookOptions<BasicSiteQuery, BasicSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BasicSiteQuery, BasicSiteQueryVariables>(BasicSiteDocument, options);
      }
export function useBasicSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasicSiteQuery, BasicSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BasicSiteQuery, BasicSiteQueryVariables>(BasicSiteDocument, options);
        }
export type BasicSiteQueryHookResult = ReturnType<typeof useBasicSiteQuery>;
export type BasicSiteLazyQueryHookResult = ReturnType<typeof useBasicSiteLazyQuery>;
export type BasicSiteQueryResult = Apollo.QueryResult<BasicSiteQuery, BasicSiteQueryVariables>;
export const BuildingsDocument = gql`
    query Buildings($siteId: ID!) {
  buildings(id: $siteId) @rest(type: "[Building]", path: "/sites/{args.id}/buildings/", method: "GET") {
    ...Building
  }
}
    ${BuildingFragmentDoc}`;

/**
 * __useBuildingsQuery__
 *
 * To run a query within a React component, call `useBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useBuildingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
      }
export function useBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
        }
export type BuildingsQueryHookResult = ReturnType<typeof useBuildingsQuery>;
export type BuildingsLazyQueryHookResult = ReturnType<typeof useBuildingsLazyQuery>;
export type BuildingsQueryResult = Apollo.QueryResult<BuildingsQuery, BuildingsQueryVariables>;
export const BuildingCalculationsDocument = gql`
    query BuildingCalculations($buildingId: ID!) {
  calculations(id: $buildingId) @rest(type: "[Calculation]", path: "/buildings/{args.id}/calculations", method: "GET") {
    ...Calculation
  }
}
    ${CalculationFragmentDoc}`;

/**
 * __useBuildingCalculationsQuery__
 *
 * To run a query within a React component, call `useBuildingCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingCalculationsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingCalculationsQuery(baseOptions: Apollo.QueryHookOptions<BuildingCalculationsQuery, BuildingCalculationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingCalculationsQuery, BuildingCalculationsQueryVariables>(BuildingCalculationsDocument, options);
      }
export function useBuildingCalculationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingCalculationsQuery, BuildingCalculationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingCalculationsQuery, BuildingCalculationsQueryVariables>(BuildingCalculationsDocument, options);
        }
export type BuildingCalculationsQueryHookResult = ReturnType<typeof useBuildingCalculationsQuery>;
export type BuildingCalculationsLazyQueryHookResult = ReturnType<typeof useBuildingCalculationsLazyQuery>;
export type BuildingCalculationsQueryResult = Apollo.QueryResult<BuildingCalculationsQuery, BuildingCalculationsQueryVariables>;
export const ReadingsDocument = gql`
    query Readings($start: String!, $end: String!, $resolution: String!) {
  readingsV1(start_date: $start, end_date: $end, resolution: $resolution) @rest(type: "ReadingsV1", path: "/readings/sites?start_date={args.start_date}&end_date={args.end_date}&resolution={args.resolution}", method: "GET") {
    ...ReadingsV1
  }
}
    ${ReadingsV1FragmentDoc}`;

/**
 * __useReadingsQuery__
 *
 * To run a query within a React component, call `useReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useReadingsQuery(baseOptions: Apollo.QueryHookOptions<ReadingsQuery, ReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadingsQuery, ReadingsQueryVariables>(ReadingsDocument, options);
      }
export function useReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadingsQuery, ReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadingsQuery, ReadingsQueryVariables>(ReadingsDocument, options);
        }
export type ReadingsQueryHookResult = ReturnType<typeof useReadingsQuery>;
export type ReadingsLazyQueryHookResult = ReturnType<typeof useReadingsLazyQuery>;
export type ReadingsQueryResult = Apollo.QueryResult<ReadingsQuery, ReadingsQueryVariables>;
export const ReadingsV2Document = gql`
    query ReadingsV2($start: String!, $end: String!, $resolution: String, $twDiff: Boolean, $aggregateBy: String, $siteIds: [ID!], $buildingIds: [ID!], $assetIds: [ID!], $virtualAssetIds: [ID!], $paramIds: [ID!], $assetTypes: [String!], $virtualAssetTypes: [String!], $paramTypes: [String!], $rawDataOnly: Boolean, $fileFormat: String, $pathBuilder: PathBuilder) {
  readingsV2(
    start_date: $start
    end_date: $end
    resolution: $resolution
    tw_diff: $twDiff
    aggregate_by: $aggregateBy
    f_site_ids: $siteIds
    f_bldng_ids: $buildingIds
    f_asset_ids: $assetIds
    f_virt_asset_ids: $virtualAssetIds
    f_param_ids: $paramIds
    f_asset_types: $assetTypes
    f_virt_asset_types: $virtualAssetTypes
    f_param_types: $paramTypes
    raw_data_only: $rawDataOnly
    file_format: $fileFormat
  ) @rest(type: "[ReadingsV2]", method: "GET", endpoint: "v2", pathBuilder: $pathBuilder) {
    ...ReadingsV2
  }
}
    ${ReadingsV2FragmentDoc}`;

/**
 * __useReadingsV2Query__
 *
 * To run a query within a React component, call `useReadingsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useReadingsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingsV2Query({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *      twDiff: // value for 'twDiff'
 *      aggregateBy: // value for 'aggregateBy'
 *      siteIds: // value for 'siteIds'
 *      buildingIds: // value for 'buildingIds'
 *      assetIds: // value for 'assetIds'
 *      virtualAssetIds: // value for 'virtualAssetIds'
 *      paramIds: // value for 'paramIds'
 *      assetTypes: // value for 'assetTypes'
 *      virtualAssetTypes: // value for 'virtualAssetTypes'
 *      paramTypes: // value for 'paramTypes'
 *      rawDataOnly: // value for 'rawDataOnly'
 *      fileFormat: // value for 'fileFormat'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useReadingsV2Query(baseOptions: Apollo.QueryHookOptions<ReadingsV2Query, ReadingsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadingsV2Query, ReadingsV2QueryVariables>(ReadingsV2Document, options);
      }
export function useReadingsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadingsV2Query, ReadingsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadingsV2Query, ReadingsV2QueryVariables>(ReadingsV2Document, options);
        }
export type ReadingsV2QueryHookResult = ReturnType<typeof useReadingsV2Query>;
export type ReadingsV2LazyQueryHookResult = ReturnType<typeof useReadingsV2LazyQuery>;
export type ReadingsV2QueryResult = Apollo.QueryResult<ReadingsV2Query, ReadingsV2QueryVariables>;
export const SiteDocument = gql`
    query Site($id: ID!) {
  site(siteId: $id) @rest(type: "Site", path: "/sites/{args.siteId}", method: "GET") {
    ...Site
  }
}
    ${SiteFragmentDoc}`;

/**
 * __useSiteQuery__
 *
 * To run a query within a React component, call `useSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteQuery(baseOptions: Apollo.QueryHookOptions<SiteQuery, SiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteQuery, SiteQueryVariables>(SiteDocument, options);
      }
export function useSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteQuery, SiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteQuery, SiteQueryVariables>(SiteDocument, options);
        }
export type SiteQueryHookResult = ReturnType<typeof useSiteQuery>;
export type SiteLazyQueryHookResult = ReturnType<typeof useSiteLazyQuery>;
export type SiteQueryResult = Apollo.QueryResult<SiteQuery, SiteQueryVariables>;
export const SitesDocument = gql`
    query Sites {
  sites @rest(type: "[Site]", path: "/sites", method: "GET") {
    ...Site
  }
  assetSummarries @rest(type: "[AssetSummary]", path: "/sites/asset_summary", method: "GET") {
    ...AssetSummary
  }
}
    ${SiteFragmentDoc}
${AssetSummaryFragmentDoc}`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitesQuery(baseOptions?: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
      }
export function useSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<SitesQuery, SitesQueryVariables>;
export const BuildingMarketEventsDocument = gql`
    query BuildingMarketEvents($buildingId: ID!, $start: String!, $end: String!, $eventCategory: EventCategoryFilterType = all_categories) {
  markets(id: $buildingId) @rest(type: "[Market]", path: "/market/buildings/{args.id}", method: "GET") {
    ...Market
  }
}
    ${MarketFragmentDoc}`;

/**
 * __useBuildingMarketEventsQuery__
 *
 * To run a query within a React component, call `useBuildingMarketEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingMarketEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingMarketEventsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useBuildingMarketEventsQuery(baseOptions: Apollo.QueryHookOptions<BuildingMarketEventsQuery, BuildingMarketEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingMarketEventsQuery, BuildingMarketEventsQueryVariables>(BuildingMarketEventsDocument, options);
      }
export function useBuildingMarketEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingMarketEventsQuery, BuildingMarketEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingMarketEventsQuery, BuildingMarketEventsQueryVariables>(BuildingMarketEventsDocument, options);
        }
export type BuildingMarketEventsQueryHookResult = ReturnType<typeof useBuildingMarketEventsQuery>;
export type BuildingMarketEventsLazyQueryHookResult = ReturnType<typeof useBuildingMarketEventsLazyQuery>;
export type BuildingMarketEventsQueryResult = Apollo.QueryResult<BuildingMarketEventsQuery, BuildingMarketEventsQueryVariables>;
export const BuildingMarketTotalReadingsDocument = gql`
    query BuildingMarketTotalReadings($buildingId: ID!, $start: String!, $end: String!, $eventCategory: EventCategoryFilterType = all_categories) {
  marketReadings(
    id: $buildingId
    start_date: $start
    end_date: $end
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/buildings/{args.id}?start_date={args.start_date}&end_date={args.end_date}&total=true&events=false&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${MarketReadingsFragmentDoc}`;

/**
 * __useBuildingMarketTotalReadingsQuery__
 *
 * To run a query within a React component, call `useBuildingMarketTotalReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingMarketTotalReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingMarketTotalReadingsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useBuildingMarketTotalReadingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingMarketTotalReadingsQuery, BuildingMarketTotalReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingMarketTotalReadingsQuery, BuildingMarketTotalReadingsQueryVariables>(BuildingMarketTotalReadingsDocument, options);
      }
export function useBuildingMarketTotalReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingMarketTotalReadingsQuery, BuildingMarketTotalReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingMarketTotalReadingsQuery, BuildingMarketTotalReadingsQueryVariables>(BuildingMarketTotalReadingsDocument, options);
        }
export type BuildingMarketTotalReadingsQueryHookResult = ReturnType<typeof useBuildingMarketTotalReadingsQuery>;
export type BuildingMarketTotalReadingsLazyQueryHookResult = ReturnType<typeof useBuildingMarketTotalReadingsLazyQuery>;
export type BuildingMarketTotalReadingsQueryResult = Apollo.QueryResult<BuildingMarketTotalReadingsQuery, BuildingMarketTotalReadingsQueryVariables>;
export const BuildingMarketsWithReadingsDocument = gql`
    query BuildingMarketsWithReadings($buildingId: ID!, $start: String!, $end: String!, $eventCategory: EventCategoryFilterType = all_categories) {
  markets(id: $buildingId) @rest(type: "[Market]", path: "/market/buildings/{args.id}", method: "GET") {
    ...BasicMarket
  }
  marketReadings(
    id: $buildingId
    start_date: $start
    end_date: $end
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/buildings/{args.id}?start_date={args.start_date}&end_date={args.end_date}&total=false&events=true&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${BasicMarketFragmentDoc}
${MarketReadingsFragmentDoc}`;

/**
 * __useBuildingMarketsWithReadingsQuery__
 *
 * To run a query within a React component, call `useBuildingMarketsWithReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingMarketsWithReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingMarketsWithReadingsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useBuildingMarketsWithReadingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingMarketsWithReadingsQuery, BuildingMarketsWithReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingMarketsWithReadingsQuery, BuildingMarketsWithReadingsQueryVariables>(BuildingMarketsWithReadingsDocument, options);
      }
export function useBuildingMarketsWithReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingMarketsWithReadingsQuery, BuildingMarketsWithReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingMarketsWithReadingsQuery, BuildingMarketsWithReadingsQueryVariables>(BuildingMarketsWithReadingsDocument, options);
        }
export type BuildingMarketsWithReadingsQueryHookResult = ReturnType<typeof useBuildingMarketsWithReadingsQuery>;
export type BuildingMarketsWithReadingsLazyQueryHookResult = ReturnType<typeof useBuildingMarketsWithReadingsLazyQuery>;
export type BuildingMarketsWithReadingsQueryResult = Apollo.QueryResult<BuildingMarketsWithReadingsQuery, BuildingMarketsWithReadingsQueryVariables>;
export const BuildingMarketsWithEventReadingsDocument = gql`
    query BuildingMarketsWithEventReadings($buildingId: ID!, $start: String!, $end: String!, $resolution: String!) {
  marketsWithEventReadings(
    id: $buildingId
    start_date: $start
    end_date: $end
    resolution: $resolution
  ) @rest(type: "[MarketWithEventReadings]", path: "/readings/buildings/{args.id}/markets?start_date={args.start_date}&end_date={args.end_date}&resolution={args.resolution}", method: "GET") {
    ...MarketWithEventReadings
  }
}
    ${MarketWithEventReadingsFragmentDoc}`;

/**
 * __useBuildingMarketsWithEventReadingsQuery__
 *
 * To run a query within a React component, call `useBuildingMarketsWithEventReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingMarketsWithEventReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingMarketsWithEventReadingsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useBuildingMarketsWithEventReadingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingMarketsWithEventReadingsQuery, BuildingMarketsWithEventReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingMarketsWithEventReadingsQuery, BuildingMarketsWithEventReadingsQueryVariables>(BuildingMarketsWithEventReadingsDocument, options);
      }
export function useBuildingMarketsWithEventReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingMarketsWithEventReadingsQuery, BuildingMarketsWithEventReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingMarketsWithEventReadingsQuery, BuildingMarketsWithEventReadingsQueryVariables>(BuildingMarketsWithEventReadingsDocument, options);
        }
export type BuildingMarketsWithEventReadingsQueryHookResult = ReturnType<typeof useBuildingMarketsWithEventReadingsQuery>;
export type BuildingMarketsWithEventReadingsLazyQueryHookResult = ReturnType<typeof useBuildingMarketsWithEventReadingsLazyQuery>;
export type BuildingMarketsWithEventReadingsQueryResult = Apollo.QueryResult<BuildingMarketsWithEventReadingsQuery, BuildingMarketsWithEventReadingsQueryVariables>;
export const SiteMarketReadingsDocument = gql`
    query SiteMarketReadings($siteId: ID!, $start: String!, $end: String!, $marketName: String, $eventCategory: EventCategoryFilterType! = all_categories) {
  marketReadings(
    id: $siteId
    start_date: $start
    end_date: $end
    market_name: $marketName
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/sites/{args.id}?start_date={args.start_date}&end_date={args.end_date}&market_name={args.market_name}&total=false&events=true&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${MarketReadingsFragmentDoc}`;

/**
 * __useSiteMarketReadingsQuery__
 *
 * To run a query within a React component, call `useSiteMarketReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMarketReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMarketReadingsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      marketName: // value for 'marketName'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useSiteMarketReadingsQuery(baseOptions: Apollo.QueryHookOptions<SiteMarketReadingsQuery, SiteMarketReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMarketReadingsQuery, SiteMarketReadingsQueryVariables>(SiteMarketReadingsDocument, options);
      }
export function useSiteMarketReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMarketReadingsQuery, SiteMarketReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMarketReadingsQuery, SiteMarketReadingsQueryVariables>(SiteMarketReadingsDocument, options);
        }
export type SiteMarketReadingsQueryHookResult = ReturnType<typeof useSiteMarketReadingsQuery>;
export type SiteMarketReadingsLazyQueryHookResult = ReturnType<typeof useSiteMarketReadingsLazyQuery>;
export type SiteMarketReadingsQueryResult = Apollo.QueryResult<SiteMarketReadingsQuery, SiteMarketReadingsQueryVariables>;
export const SiteMarketsWithReadingsDocument = gql`
    query SiteMarketsWithReadings($siteId: ID!, $start: String!, $end: String!, $marketName: String, $eventCategory: EventCategoryFilterType = all_categories) {
  markets(id: $siteId) @rest(type: "[Market]", path: "/market/sites/{args.id}", method: "GET") {
    ...BasicMarket
  }
  marketReadings(
    id: $siteId
    start_date: $start
    end_date: $end
    market_name: $marketName
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/sites/{args.id}?start_date={args.start_date}&end_date={args.end_date}&market_name={args.market_name}&total=false&events=true&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${BasicMarketFragmentDoc}
${MarketReadingsFragmentDoc}`;

/**
 * __useSiteMarketsWithReadingsQuery__
 *
 * To run a query within a React component, call `useSiteMarketsWithReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMarketsWithReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMarketsWithReadingsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      marketName: // value for 'marketName'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useSiteMarketsWithReadingsQuery(baseOptions: Apollo.QueryHookOptions<SiteMarketsWithReadingsQuery, SiteMarketsWithReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMarketsWithReadingsQuery, SiteMarketsWithReadingsQueryVariables>(SiteMarketsWithReadingsDocument, options);
      }
export function useSiteMarketsWithReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMarketsWithReadingsQuery, SiteMarketsWithReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMarketsWithReadingsQuery, SiteMarketsWithReadingsQueryVariables>(SiteMarketsWithReadingsDocument, options);
        }
export type SiteMarketsWithReadingsQueryHookResult = ReturnType<typeof useSiteMarketsWithReadingsQuery>;
export type SiteMarketsWithReadingsLazyQueryHookResult = ReturnType<typeof useSiteMarketsWithReadingsLazyQuery>;
export type SiteMarketsWithReadingsQueryResult = Apollo.QueryResult<SiteMarketsWithReadingsQuery, SiteMarketsWithReadingsQueryVariables>;
export const SiteMarketTotalReadingsDocument = gql`
    query SiteMarketTotalReadings($siteId: ID!, $start: String!, $end: String!, $eventCategory: EventCategoryFilterType = all_categories) {
  marketReadings(
    id: $siteId
    start_date: $start
    end_date: $end
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/sites/{args.id}?start_date={args.start_date}&end_date={args.end_date}&total=true&events=false&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${MarketReadingsFragmentDoc}`;

/**
 * __useSiteMarketTotalReadingsQuery__
 *
 * To run a query within a React component, call `useSiteMarketTotalReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMarketTotalReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMarketTotalReadingsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useSiteMarketTotalReadingsQuery(baseOptions: Apollo.QueryHookOptions<SiteMarketTotalReadingsQuery, SiteMarketTotalReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMarketTotalReadingsQuery, SiteMarketTotalReadingsQueryVariables>(SiteMarketTotalReadingsDocument, options);
      }
export function useSiteMarketTotalReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMarketTotalReadingsQuery, SiteMarketTotalReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMarketTotalReadingsQuery, SiteMarketTotalReadingsQueryVariables>(SiteMarketTotalReadingsDocument, options);
        }
export type SiteMarketTotalReadingsQueryHookResult = ReturnType<typeof useSiteMarketTotalReadingsQuery>;
export type SiteMarketTotalReadingsLazyQueryHookResult = ReturnType<typeof useSiteMarketTotalReadingsLazyQuery>;
export type SiteMarketTotalReadingsQueryResult = Apollo.QueryResult<SiteMarketTotalReadingsQuery, SiteMarketTotalReadingsQueryVariables>;
export const SiteMarketsDocument = gql`
    query SiteMarkets($siteId: ID!) {
  markets(id: $siteId) @rest(type: "[Market]", path: "/market/sites/{args.id}", method: "GET") {
    ...BasicMarket
  }
}
    ${BasicMarketFragmentDoc}`;

/**
 * __useSiteMarketsQuery__
 *
 * To run a query within a React component, call `useSiteMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMarketsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteMarketsQuery(baseOptions: Apollo.QueryHookOptions<SiteMarketsQuery, SiteMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMarketsQuery, SiteMarketsQueryVariables>(SiteMarketsDocument, options);
      }
export function useSiteMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMarketsQuery, SiteMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMarketsQuery, SiteMarketsQueryVariables>(SiteMarketsDocument, options);
        }
export type SiteMarketsQueryHookResult = ReturnType<typeof useSiteMarketsQuery>;
export type SiteMarketsLazyQueryHookResult = ReturnType<typeof useSiteMarketsLazyQuery>;
export type SiteMarketsQueryResult = Apollo.QueryResult<SiteMarketsQuery, SiteMarketsQueryVariables>;
export const SitesMarketTotalReadingsDocument = gql`
    query SitesMarketTotalReadings($start: String!, $end: String!, $eventCategory: EventCategoryFilterType = all_categories) {
  marketReadings(
    start_date: $start
    end_date: $end
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/sites?start_date={args.start_date}&end_date={args.end_date}&total=true&events=false&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${MarketReadingsFragmentDoc}`;

/**
 * __useSitesMarketTotalReadingsQuery__
 *
 * To run a query within a React component, call `useSitesMarketTotalReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesMarketTotalReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesMarketTotalReadingsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useSitesMarketTotalReadingsQuery(baseOptions: Apollo.QueryHookOptions<SitesMarketTotalReadingsQuery, SitesMarketTotalReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesMarketTotalReadingsQuery, SitesMarketTotalReadingsQueryVariables>(SitesMarketTotalReadingsDocument, options);
      }
export function useSitesMarketTotalReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesMarketTotalReadingsQuery, SitesMarketTotalReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesMarketTotalReadingsQuery, SitesMarketTotalReadingsQueryVariables>(SitesMarketTotalReadingsDocument, options);
        }
export type SitesMarketTotalReadingsQueryHookResult = ReturnType<typeof useSitesMarketTotalReadingsQuery>;
export type SitesMarketTotalReadingsLazyQueryHookResult = ReturnType<typeof useSitesMarketTotalReadingsLazyQuery>;
export type SitesMarketTotalReadingsQueryResult = Apollo.QueryResult<SitesMarketTotalReadingsQuery, SitesMarketTotalReadingsQueryVariables>;
export const SitesMarketsWithReadingsDocument = gql`
    query SitesMarketsWithReadings($start: String!, $end: String!, $marketName: String, $eventCategory: EventCategoryFilterType = all_categories) {
  markets @rest(type: "[Market]", path: "/market/sites", method: "GET") {
    ...BasicMarket
  }
  marketReadings(
    start_date: $start
    end_date: $end
    market_name: $marketName
    event_category: $eventCategory
  ) @rest(type: "MarketReadings", path: "/market/revenue/sites?start_date={args.start_date}&end_date={args.end_date}&market_name={args.market_name}&total=false&events=true&event_category={args.event_category}", method: "GET") {
    ...MarketReadings
  }
}
    ${BasicMarketFragmentDoc}
${MarketReadingsFragmentDoc}`;

/**
 * __useSitesMarketsWithReadingsQuery__
 *
 * To run a query within a React component, call `useSitesMarketsWithReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesMarketsWithReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesMarketsWithReadingsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      marketName: // value for 'marketName'
 *      eventCategory: // value for 'eventCategory'
 *   },
 * });
 */
export function useSitesMarketsWithReadingsQuery(baseOptions: Apollo.QueryHookOptions<SitesMarketsWithReadingsQuery, SitesMarketsWithReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesMarketsWithReadingsQuery, SitesMarketsWithReadingsQueryVariables>(SitesMarketsWithReadingsDocument, options);
      }
export function useSitesMarketsWithReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesMarketsWithReadingsQuery, SitesMarketsWithReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesMarketsWithReadingsQuery, SitesMarketsWithReadingsQueryVariables>(SitesMarketsWithReadingsDocument, options);
        }
export type SitesMarketsWithReadingsQueryHookResult = ReturnType<typeof useSitesMarketsWithReadingsQuery>;
export type SitesMarketsWithReadingsLazyQueryHookResult = ReturnType<typeof useSitesMarketsWithReadingsLazyQuery>;
export type SitesMarketsWithReadingsQueryResult = Apollo.QueryResult<SitesMarketsWithReadingsQuery, SitesMarketsWithReadingsQueryVariables>;
export const BasicSubmetersDocument = gql`
    query BasicSubmeters($buildingId: ID!) {
  submeters(id: $buildingId) @rest(type: "[Submeter]", path: "/buildings/{args.id}/submeters", method: "GET") {
    ...BasicSubmeter
  }
}
    ${BasicSubmeterFragmentDoc}`;

/**
 * __useBasicSubmetersQuery__
 *
 * To run a query within a React component, call `useBasicSubmetersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicSubmetersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicSubmetersQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBasicSubmetersQuery(baseOptions: Apollo.QueryHookOptions<BasicSubmetersQuery, BasicSubmetersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BasicSubmetersQuery, BasicSubmetersQueryVariables>(BasicSubmetersDocument, options);
      }
export function useBasicSubmetersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasicSubmetersQuery, BasicSubmetersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BasicSubmetersQuery, BasicSubmetersQueryVariables>(BasicSubmetersDocument, options);
        }
export type BasicSubmetersQueryHookResult = ReturnType<typeof useBasicSubmetersQuery>;
export type BasicSubmetersLazyQueryHookResult = ReturnType<typeof useBasicSubmetersLazyQuery>;
export type BasicSubmetersQueryResult = Apollo.QueryResult<BasicSubmetersQuery, BasicSubmetersQueryVariables>;
export const SubmetersDocument = gql`
    query Submeters($buildingId: ID!, $start: String!, $end: String!, $resolution: String!) {
  submeters(
    id: $buildingId
    start_date: $start
    end_date: $end
    resolution: $resolution
  ) @rest(type: "[Submeter]", path: "/buildings/{args.id}/submeters", method: "GET") {
    ...Submeter
  }
}
    ${SubmeterFragmentDoc}`;

/**
 * __useSubmetersQuery__
 *
 * To run a query within a React component, call `useSubmetersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmetersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmetersQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useSubmetersQuery(baseOptions: Apollo.QueryHookOptions<SubmetersQuery, SubmetersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubmetersQuery, SubmetersQueryVariables>(SubmetersDocument, options);
      }
export function useSubmetersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubmetersQuery, SubmetersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubmetersQuery, SubmetersQueryVariables>(SubmetersDocument, options);
        }
export type SubmetersQueryHookResult = ReturnType<typeof useSubmetersQuery>;
export type SubmetersLazyQueryHookResult = ReturnType<typeof useSubmetersLazyQuery>;
export type SubmetersQueryResult = Apollo.QueryResult<SubmetersQuery, SubmetersQueryVariables>;
export const DownloadFiltersDocument = gql`
    query DownloadFilters {
  downloadFilters @rest(type: "DownloadFilters", path: "/download/filters", method: "GET", endpoint: "v2") {
    ...DownloadFilters
  }
}
    ${DownloadFiltersFragmentDoc}`;

/**
 * __useDownloadFiltersQuery__
 *
 * To run a query within a React component, call `useDownloadFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDownloadFiltersQuery(baseOptions?: Apollo.QueryHookOptions<DownloadFiltersQuery, DownloadFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadFiltersQuery, DownloadFiltersQueryVariables>(DownloadFiltersDocument, options);
      }
export function useDownloadFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadFiltersQuery, DownloadFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadFiltersQuery, DownloadFiltersQueryVariables>(DownloadFiltersDocument, options);
        }
export type DownloadFiltersQueryHookResult = ReturnType<typeof useDownloadFiltersQuery>;
export type DownloadFiltersLazyQueryHookResult = ReturnType<typeof useDownloadFiltersLazyQuery>;
export type DownloadFiltersQueryResult = Apollo.QueryResult<DownloadFiltersQuery, DownloadFiltersQueryVariables>;
export const DownloadOptionsDocument = gql`
    query DownloadOptions($startDate: String, $endDate: String, $siteIds: [ID!], $buildingIds: [ID!], $marketNames: [String!], $assetTypes: [String!], $measurementTypes: [String!], $pathBuilder: PathBuilder) {
  downloadOptions(
    start_date: $startDate
    end_date: $endDate
    f_site_ids: $siteIds
    f_bldng_ids: $buildingIds
    markets: $marketNames
    asset_types: $assetTypes
    measurement_types: $measurementTypes
  ) @rest(type: "DownloadOption", method: "GET", pathBuilder: $pathBuilder, endpoint: "v2") {
    ...DownloadOption
  }
}
    ${DownloadOptionFragmentDoc}`;

/**
 * __useDownloadOptionsQuery__
 *
 * To run a query within a React component, call `useDownloadOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadOptionsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      siteIds: // value for 'siteIds'
 *      buildingIds: // value for 'buildingIds'
 *      marketNames: // value for 'marketNames'
 *      assetTypes: // value for 'assetTypes'
 *      measurementTypes: // value for 'measurementTypes'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useDownloadOptionsQuery(baseOptions?: Apollo.QueryHookOptions<DownloadOptionsQuery, DownloadOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadOptionsQuery, DownloadOptionsQueryVariables>(DownloadOptionsDocument, options);
      }
export function useDownloadOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadOptionsQuery, DownloadOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadOptionsQuery, DownloadOptionsQueryVariables>(DownloadOptionsDocument, options);
        }
export type DownloadOptionsQueryHookResult = ReturnType<typeof useDownloadOptionsQuery>;
export type DownloadOptionsLazyQueryHookResult = ReturnType<typeof useDownloadOptionsLazyQuery>;
export type DownloadOptionsQueryResult = Apollo.QueryResult<DownloadOptionsQuery, DownloadOptionsQueryVariables>;